import { SlicePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  Renderer2,
  inject,
  input,
  viewChild,
} from '@angular/core';
import { DeviceAdvancedSettings } from '@designage/gql';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [TranslatePipe, NgbTooltipModule, SlicePipe],
  selector: 'app-device-reboot-time',
  template: `
    @defer (on viewport) {
      <div
        #badge
        style="min-width: 4rem"
        class="px-1 fw-bold badge"
        [class.bg-success]="advancedSettings().scheduleDailyReboot"
        [class.bg-secondary]="!advancedSettings().scheduleDailyReboot"
      >
        {{
          (advancedSettings().scheduleDailyReboot &&
          advancedSettings().rebootTime !== null
            ? (advancedSettings().rebootTime ?? '' | slice: 0 : 5)
            : 'NOT_SET'
          ) | translate
        }}
      </div>
    } @placeholder {
      <div
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      >
        <span class="text-secondary visually-hidden">
          {{ 'LOADING' | translate }}...
        </span>
      </div>
    }
  `,
  styles: [``],
})
export class DeviceRebootTimeComponent {
  renderer = inject(Renderer2);
  advancedSettings = input.required<DeviceAdvancedSettings>();
  protected badge = viewChild<ElementRef>('badge');

  constructor() {}

  isNumber(val: any): boolean {
    return typeof val === 'number';
  }
}
