import {
  Component,
  Input,
  Output,
  EventEmitter,
  inject,
  ViewChild,
  ChangeDetectionStrategy,
  signal,
  HostListener,
} from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import {
  DeviceBulkAction,
  Maybe,
  PublishChannelsContentGQL,
} from '@designage/gql';
import {
  EncryptionService,
  CurrentUserService,
  DeviceDataService,
  SlidePanelService,
  ResponsiveUiService,
} from '@desquare/services';
import { IDesignageDataTableColumns } from '@desquare/interfaces';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { DeviceCreateDialogComponent } from '../device-create-dialog/device-create-dialog.component';
import { DeviceBulkActionDialogComponent } from '@desquare/components/common/src/modals/device-bulk-action-dialog.component';
import { domConstants } from '@desquare/constants';
import { DeviceData } from '@desquare/types';
import { bulkActionsUtil } from '@desquare/utils';
import { DesignageDataTableComponent } from '@desquare/components/common/src/designage-data-table/designage-data-table.component';
import { NgClass } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';
import { DeviceBrightnessComponent } from '@desquare/components/common/src/device-ui-components/device-brightness.component';
import { DeviceVolumeComponent } from '@desquare/components/common/src/device-ui-components/device-volume.component';
import { DeviceRebootTimeComponent } from '@desquare/components/common/src/device-ui-components/device-reboot-time.component';
import { TableDateTimeComponent } from '@desquare/components/common/src/designage-data-table/table-components/table-dateTime.component';
import { DevicesStore } from '@desquare/stores';
import { DeviceStatusInfo } from '@desquare/models';
import { DeviceMemoryComponent } from '@desquare/components/common/src/device-ui-components/device-memory.component';
import { DeviceStorageComponent } from '@desquare/components/common/src/device-ui-components/device-storage.component';

@Component({
  standalone: true,
  imports: [
    NgClass,
    TranslatePipe,
    NgbTooltip,
    DesignageDataTableComponent,
    DesignageDataTableComponent,
    DeviceBrightnessComponent,
    DeviceVolumeComponent,
    DeviceRebootTimeComponent,
    DeviceMemoryComponent,
    TableDateTimeComponent,
    RouterOutlet,
    DeviceStorageComponent,
  ],
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceListComponent {
  @HostListener('window:focus', ['$event'])
  onFocus() {
    this.deviceDataService.refreshDevices();
    console.log('devices focus');
  }

  publishChannelsContentGql = inject(PublishChannelsContentGQL);
  @ViewChild(DesignageDataTableComponent)
  designageDataTable!: DesignageDataTableComponent;

  devicesStore = inject(DevicesStore);
  slidePanelService = inject(SlidePanelService);

  private deviceDataService = inject(DeviceDataService);

  @Input() buttonsHidden = false;
  @Input() redirectOnSelect = true;
  @Input() channelId?: Maybe<string>;
  @Input() locationId?: string;
  @Input() showBulkAction = true;
  @Input() enableCheckbox = true;
  @Input() enableSlidePanel = true;

  @Output() deviceSelect = new EventEmitter<DeviceData>();

  selectedRows = signal<DeviceData[]>([]);
  loaderMessage!: string;
  DeviceBulkAction = DeviceBulkAction;
  bulkActions = Object.values(DeviceBulkAction);
  bulkActionsUtil = bulkActionsUtil;

  pageSizeOptions = domConstants.DATA_PAGE_SIZE_OPTIONS;
  profileId!: Maybe<string>;

  devices = this.devicesStore.devices;
  loading = this.devicesStore.loading;
  onlineDevices = this.devicesStore.onlineDevices;
  offlineDevices = this.devicesStore.offlineDevices;

  desColumns: IDesignageDataTableColumns[] = [
    {
      fieldName: 'status',
      name: '',
      type: 'status-indicator',
      visible: 'mandatory',
      flex: '0',
      style: 'min-width:3rem;max-width:3rem;',
      childComponentStyle: 'justify-content:center;',
      disableSorting: true,
    },
    {
      fieldName: 'name',
      name: 'NAME',
      type: 'string',
      visible: 'mandatory',
      flex: '2',
    },
    {
      fieldName: 'channel.name',
      name: 'channel.CHANNEL',
      type: 'string',
      visible: true,
      groupable: true,
    },
    {
      fieldName: 'location.name',
      name: 'LOCATION',
      type: 'string',
      visible: true,
      groupable: true,
    },
    {
      fieldName: 'deviceInfo.model',
      name: 'APPLICATION_MODEL',
      type: 'string',
      visible: true,
      groupable: true,
    },
    {
      fieldName: 'deviceInfo.applicationType',
      name: 'APPLICATION_TYPE',
      type: 'string',
      decorator: 'osType',
      visible: true,
      groupable: true,
    },
    {
      fieldName: 'deviceInfo.applicationVersion',
      name: 'APPLICATION_VERSION',
      type: 'string',
      visible: false,
      flex: '0.5',
      groupable: true,
    },
    {
      fieldName: 'deviceInfo.appletVersion',
      name: 'APPLET_VERSION',
      type: 'string',
      visible: true,
      flex: '0.5',
      groupable: true,
    },
    {
      fieldName: 'deviceInfo.currentTime.currentDate',
      name: 'LOCAL_DEVICE_TIME',
      type: 'template',
      templateRef: 'deviceTime',
      visible: true,
      disableSorting: true,
    },
    {
      fieldName: 'deviceInfo.currentTime.timezone',
      name: 'TIME_ZONE',
      type: 'string',
      visible: true,
      groupable: true,
    },
    {
      fieldName: 'deviceInfo.firmware',
      name: 'FIRMWARE',
      type: 'string',
      visible: true,
      groupable: true,
    },
    {
      fieldName: 'deviceInfo.screen.isPoweredOn',
      name: 'DISPLAY_POWER',
      type: 'template',
      templateRef: 'screenPower',
      visible: true,
      groupable: false,
      style: 'min-width:8rem; text-align: center !important;',
      childComponentStyle: 'justify-content:center;',
    },
    {
      fieldName: 'deviceInfo.screen.volume',
      name: 'DISPLAY_VOLUME',
      type: 'template',
      templateRef: 'screenVolume',
      visible: true,
      groupable: false,
      style: 'text-align: center !important;',
      childComponentStyle: 'justify-content:center;',
    },
    {
      fieldName: 'advancedSettings.rebootTime',
      name: 'REBOOT_SCHEDULE',
      type: 'template',
      templateRef: 'rebootTime',
      visible: true,
      groupable: false,
      style: 'text-align: center !important;',
      childComponentStyle: 'justify-content:center;',
    },
    {
      fieldName: 'deviceInfo.memory',
      name: 'DEVICE_MEMORY',
      type: 'template',
      templateRef: 'memory',
      visible: true,
      groupable: false,
      style: 'text-align: center !important;',
      childComponentStyle: 'justify-content:center;',
    },
    {
      fieldName: 'deviceInfo.storageUnit',
      name: 'DEVICE_STORAGE',
      type: 'template',
      templateRef: 'storage',
      visible: true,
      groupable: false,
      style: 'text-align: center !important;',
      childComponentStyle: 'justify-content:center;',
    },
    {
      fieldName: 'lastPing',
      name: 'PING',
      type: 'template',
      templateRef: 'deviceTimeAgo',
      visible: 'mandatory',
    },
  ];

  constructor(
    private encryptionService: EncryptionService,
    private router: Router,
    private modalService: NgbModal,
    private currentUserService: CurrentUserService,
    public responsiveUiService: ResponsiveUiService,
  ) {
    // effect(() => {
    //   console.log(
    //     'devices',
    //     this.devices().find((x) => x.name === 'Drottninggatan 29 Hiss 2')
    //   );
    // });
  }

  isSlidePanelOpen = this.slidePanelService.getIsPanelOpen();
  selectedDeviceId = this.slidePanelService.getPanelComponentId();
  get canVerifyHash() {
    return this.currentUserService.canManageDevices;
  }

  refreshDevices() {
    this.deviceDataService.refreshDevices();
  }

  onRowClick(e: unknown) {
    const device = e as DeviceData;
    if (this.redirectOnSelect) {
      this.slidePanelService.setPanelComponentId(device.id);
      this.navigateToDevice(device);
    } else {
      if (device?.id) {
        this.slidePanelService.setPanelComponentId(device.id);

        this.deviceSelect.emit(device);
      }
    }
  }

  navigateToDevice(device: DeviceData) {
    if (device && device.id) {
      const id = this.encryptionService.encrypt(device.id);
      if (this.currentUserService.canManageProfiles) {
        this.router.navigate(['/profile/devices/manage', id]);
      } else if (this.currentUserService.canViewProfiles) {
        this.router.navigate(['/device/detail', id]);
      }
    }
  }

  provision() {
    const modalRef = this.modalService.open(DeviceCreateDialogComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    if (this.channelId) {
      modalRef.componentInstance.channelId = this.channelId;
    }

    if (this.profileId) {
      modalRef.componentInstance.profileId = this.profileId;
    }

    modalRef.result.then(
      () => {
        // refresh UI
        this.deviceDataService.refreshDevices();
      },
      () => {},
    );
  }

  bulkActionClick(action: DeviceBulkAction) {
    const applicableDevices = this.getApplicableDevices(
      action,
      this.selectedRows(),
    );
    const modal = this.modalService.open(DeviceBulkActionDialogComponent, {
      backdrop: 'static',
    });
    modal.componentInstance.devices = applicableDevices;
    modal.componentInstance.action = action;
    modal.result
      .then(() => {
        console.log('modal closed', applicableDevices);

        this.doDeviceAction(
          action,
          applicableDevices,
          modal.componentInstance.extra,
        );
        this.designageDataTable.selection.clear();
      })
      .catch((error) => {
        console.error('error in bulkaction:', error);
      });
  }

  getApplicableDevices(action: DeviceBulkAction, devices: DeviceData[]) {
    switch (action) {
      case DeviceBulkAction.DeviceAppletReload:
      case DeviceBulkAction.DeviceClearCache:
      case DeviceBulkAction.DeviceDisplayOff:
        return devices.filter(
          (device) =>
            device.status === DeviceStatusInfo.Online ||
            this.currentUserService.isSuperAdmin,
        );

      case DeviceBulkAction.DeviceDisplayOn:
      case DeviceBulkAction.DeviceReboot:
      case DeviceBulkAction.DeviceAppletUpdateVersion:
        // Applies to all channels
        return devices;
      default:
        return [];
    }
  }

  async doDeviceAction(
    action: DeviceBulkAction,
    devices: DeviceData[],
    extraParam: string = '',
  ) {
    switch (action) {
      case DeviceBulkAction.DeviceDisplayOn:
        for (const device of devices) {
          this.deviceDataService.turnScreenOn(device.id);
        }
        break;
      case DeviceBulkAction.DeviceDisplayOff:
        for (const device of devices) {
          this.deviceDataService.turnScreenOff(device.id);
        }
        break;
      case DeviceBulkAction.DeviceAppletUpdateVersion:
        const deviceIds = devices.map((d) => d.id);
        const override =
          this.currentUserService.isSuperAdmin &&
          (extraParam === 'SMIL' || extraParam === 'CLASSIC');
        const applet = override ? extraParam : 'LATEST';
        await this.deviceDataService.bulkUpdateDeviceApplet(deviceIds, applet);
        break;
      case DeviceBulkAction.DeviceAppletReload:
        for (const device of devices) {
          this.deviceDataService.reloadDeviceApp(device.id);
        }
        break;
      case DeviceBulkAction.DeviceReboot:
        for (const device of devices) {
          this.deviceDataService.rebootDevice(device.id);
        }
        break;
      case DeviceBulkAction.DeviceClearCache:
        await this.deviceDataService.bulkClearDeviceCache(
          devices.map((x) => x.id),
        );
        break;
        // case DeviceBulkAction.DeleteChannel:
        //   if (confirmed) {
        //     // somebody already confirmed with a popup: delete directly
        //     this.delete(channel);
        //   } else {
        //     this.deleteWithPopup(channel);
        //   }

        break;
      default: {
        // console.log('UNSUPPORTED ACTION ', action);
      }
    }
  }
}
