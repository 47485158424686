import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Renderer2,
  effect,
  inject,
  input,
  viewChild,
} from '@angular/core';
import { Maybe } from '@designage/gql';
import { IDeviceInfo } from '@desquare/interfaces';
import { getDeviceTime, getTimezoneOffsetByName } from '@desquare/utils';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslatePipe, NgbTooltipModule, NgClass],
  selector: 'app-device-brightness',
  template: `
    @if (
      deviceInfo().screen !== null && getCurrentBrightness();
      as currentBrightness
    ) {
      @if (deviceInfo().screen?.isPoweredOn) {
        @if (isNumber(currentBrightness.brightness)) {
          <div #badge style="min-width: 4rem" class="px-2 fs-6 badge">
            @if (currentBrightness.schedule !== 'NO_SCHEDULE') {
              <i
                [ngClass]="
                  currentBrightness.schedule === 'DEVICE_BRIGHTNESS_NIGHT'
                    ? 'ri-moon-fill'
                    : 'ri-sun-fill'
                "
                class="pe-1"
              ></i>
            }
            @if (currentBrightness.brightness) {
              <span class="px-1">
                {{ currentBrightness.brightness.toString() + '%' }}
              </span>
            }
          </div>
        } @else {
          {{ 'NO_DATA' | translate }}
        }
      } @else {
        <div style="min-width: 6rem" class="px-2 fs-6 badge bg-secondary">
          <i class="ri-shut-down-line pe-1"></i>
          <span class="px-1">
            {{ 'DISPLAY_POWER_SAVE' | translate }}
          </span>
        </div>
      }
    } @else {
      <div class="text-secondary">
        {{ 'NO_DATA' | translate }}
      </div>
    }
  `,
})
export class DeviceBrightnessComponent {
  deviceInfo = input.required<IDeviceInfo>();
  value = input<Maybe<number>>(undefined);
  renderer = inject(Renderer2);
  protected badge = viewChild<ElementRef>('badge');

  isNumber(val: any): boolean {
    return typeof val === 'number';
  }

  constructor() {
    effect(() => {
      let brightness;
      if (this.value()) {
        brightness = this.value();
      } else {
        brightness = this.getCurrentBrightness().brightness;
      }
      if (brightness != null && this.badge()?.nativeElement)
        this.renderer.setStyle(
          this.badge()?.nativeElement,
          'background',
          `linear-gradient(90deg, rgb(24 136 100) ${
            brightness - 1
          }%,  rgb(108 117 125) ${brightness}%)`,
        );
    });
  }

  getCurrentBrightness(): {
    brightness: Maybe<number>;
    schedule: Maybe<
      'NO_SCHEDULE' | 'DEVICE_BRIGHTNESS_DAY' | 'DEVICE_BRIGHTNESS_NIGHT'
    >;
  } {
    if (this.value()) {
      return {
        brightness: this.value(),
        schedule: null,
      };
    }
    const timezoneOffset = getTimezoneOffsetByName(
      this.deviceInfo().currentTime?.timezone,
    );
    const brightness = this.deviceInfo().screen?.brightness;
    if (
      this.deviceInfo().screen?.brightness &&
      this.deviceInfo().currentTime?.currentDate &&
      timezoneOffset
    ) {
      const currentDeviceTimeString = getDeviceTime(
        this.deviceInfo().currentTime?.currentDate,
        timezoneOffset,
      );
      if (brightness?.brightness1 === brightness?.brightness2)
        return {
          brightness: brightness?.brightness1,
          schedule: 'NO_SCHEDULE',
        };
      if (
        currentDeviceTimeString >= brightness?.timeFrom1! &&
        currentDeviceTimeString <= brightness?.timeFrom2!
      )
        return {
          brightness: brightness?.brightness1,
          schedule: 'DEVICE_BRIGHTNESS_DAY',
        };
      if (
        currentDeviceTimeString < brightness?.timeFrom1! ||
        currentDeviceTimeString > brightness?.timeFrom2!
      )
        return {
          brightness: brightness?.brightness2,
          schedule: 'DEVICE_BRIGHTNESS_NIGHT',
        };
    }
    return { brightness: null, schedule: null };
  }
}
