import { NgClass } from '@angular/common';
import {
  Component,
  ElementRef,
  Renderer2,
  effect,
  inject,
  input,
  viewChild,
} from '@angular/core';
import { IDeviceInfo } from '@desquare/interfaces';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [NgClass, TranslatePipe, NgbTooltipModule],
  selector: 'app-device-volume',
  template: `
    @defer (on viewport) {
      @if (isNumber(deviceInfo().screen?.volume)) {
        <div #badge style="min-width: 4rem" class="px-1 fw-bold badge">
          <i
            [ngClass]="
              deviceInfo().screen?.volume! >= 1
                ? 'ri-volume-up-fill'
                : 'ri-volume-mute-fill'
            "
            class="pe-1"
          ></i>
          <span>
            {{
              deviceInfo().screen?.volume === 0
                ? ('MUTE' | translate)
                : deviceInfo().screen?.volume + '%'
            }}
          </span>
        </div>
      }
    } @placeholder {
      <div
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      >
        <span class="text-secondary visually-hidden">
          {{ 'LOADING' | translate }}...
        </span>
      </div>
    }
  `,
  styles: [``],
})
export class DeviceVolumeComponent {
  renderer = inject(Renderer2);
  deviceInfo = input.required<IDeviceInfo>();
  protected badge = viewChild<ElementRef>('badge');

  constructor() {
    effect(() => {
      const volume = this.deviceInfo().screen?.volume;
      if (this.isNumber(volume) && this.badge()?.nativeElement)
        this.renderer.setStyle(
          this.badge()?.nativeElement,
          'background',
          `linear-gradient(90deg, rgb(24 136 100) ${
            volume! - 1
          }%,  rgb(108 117 125) ${volume}%)`,
        );
    });
  }

  isNumber(val: any): boolean {
    return typeof val === 'number';
  }
}
